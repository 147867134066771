import React from "react";
import ReactHtmlParser from "react-html-parser";

import AccordionButton from "src/components/accordion-button.js";
import DateSelection from "src/components/date-picker";
import DynamicStats from "src/components/dynamic-content-stats";
import Gallery from "src/components/gallery/gallery";
import TableFiles from "src/components/table-files";
import SingleFileDownload from "src/components/table-files-single";
import VisualizerIframe from "src/components/visualizer-iframe";
import ReactIframeResizer from "react-iframe-resizer-super";
import TitleReplace from "./subpage-title-adjustment";
import ExecutiveImageLink from "./executive-image-link";
import ExecutiveNameLink from "./executive-name-link";
import GalleryAlbum from "./gallery-album";

/**
 *  Parent page content (not used often)
 */
class ParentPageArticle extends React.Component {

  static uniqueID = 100;

  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  /**
   * Replace HTML from WP content with React components. Specifically, files tables (from WP File Download) and Visualizer tables.
   */
  replaceWithComponents(node, index) {

    // looking for a specific tag? add your code in this method,
    // then tell the render method below when to run replaceWithComponents

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("gform_wrapper") > -1)) {

      let formId = node.attribs.id.split("_").slice(-1);
      let formUrl = 'https://thehelm.polb.com/gfembed/?f=' + formId;

      const iframeResizerOptions = {
        checkOrigin: false,
        minHeight: 500,
        scrolling: false,
        heightCalculationMethod: 'documentElementOffset'
      };

      ParentPageArticle.uniqueID++;

      return (
        <ReactIframeResizer
          key={'gform-' + ParentPageArticle.uniqueID}
          id={'gform-' + ParentPageArticle.uniqueID}
          src={formUrl}
          iframeResizerOptions={iframeResizerOptions}
          iframeResizerUrl={false}
          className="stellar-gravity-form" />
      );
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("wpdt-c") > -1)) {
      const isIE = /*@cc_on!@*/false || !!document.documentMode;

      if (Array.isArray(node.children)) {
        let tableNode = node.children.find(current => {
          return current.name === "table" && current.attribs && current.attribs['data-wpdatatable_id'];
        });

        if (tableNode) {
          let tableID = tableNode.attribs['data-wpdatatable_id'];
          let tableUrl = 'https://thehelm.polb.com/stellar_custom_table/table' + tableID;

          const iframeResizerOptions = isIE ?
            {
              checkOrigin: false,
              minHeight: 200,
              scrolling: false,
              heightCalculationMethod: 'lowestElement'
            }
            :
            {
              checkOrigin: false,
              minHeight: 200,
              scrolling: false,
              heightCalculationMethod: 'documentElementOffset'
            };

          // for iframe sizer to work, we need a unique ID every time we generate this iframe
          // otherwise, on tab switches, the iframe wont be resized
          ParentPageArticle.uniqueID++;

          return (
            <ReactIframeResizer
              key={'custom-table-' + ParentPageArticle.uniqueID}
              id={'custom-table-' + ParentPageArticle.uniqueID}
              src={tableUrl}
              iframeResizerOptions={iframeResizerOptions}
              iframeResizerUrl={false}
              className="stellar-custom-table" />
          );
        }

      }

      return <h3>Could not load this table!</h3>;
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("stellar-collapse__title") > -1)) {
      return <AccordionButton yearTitle={node.children[0].data} />
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("ginput_container_date") > -1)) {
      return <DateSelection />
    }

    if (node.type === 'tag' && node.name === 'ul' && node.attribs.class && (node.attribs.class.indexOf("stats") > -1)) {
      return <DynamicStats statOne={node.children[0]} statTwo={node.children[1]} statThree={node.children[2]} />
    }

    if (node.type === 'tag' && node.name === 'img' && node.attribs.class && (node.attribs.class.indexOf("executive__img") > -1)) {
      return <ExecutiveImageLink execImg={node} execLink={node.next} />
    }

    if (node.type === 'tag' && node.name === 'strong' && node.children && node.children[0].attribs && node.parent && node.parent.attribs && (node.parent.attribs.class === "executive__description")) {
      return <ExecutiveNameLink execName={node} />
    }

    if (node.type === 'tag' && node.name === 'iframe' && node.attribs.class && (node.attribs.class.indexOf("board-meetings-iframe") > -1)) {

      const iframeResizerOptions = {
        checkOrigin: false,
        minHeight: 200,
        scrolling: false,
        heightCalculationMethod: 'documentElementOffset'
      };

      // for iframe sizer to work, we need a unique ID every time we generate this iframe
      // otherwise, on tab switches, the iframe wont be resized
      ParentPageArticle.uniqueID++;

      return (
        <ReactIframeResizer
          key={'board-meetings-' + ParentPageArticle.uniqueID}
          id={'board-meetings-' + ParentPageArticle.uniqueID}
          src={node.attribs.src}
          iframeResizerOptions={iframeResizerOptions}
          iframeResizerUrl={false}
        />
      );

    }

    // Replace hidden files table div with TableFiles component that receives files table id as a prop
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("fileCategoryId") > -1)) {
      return <TableFiles key={index} tableData={this.props.files.find(obj => obj.filesId === node.children[0].data)} perPage="15" />;
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("wpfd-single-file") > -1)) {
      return <SingleFileDownload key={index} data={node} />
    }

    // Eliminate strange break tag in Executives block
    if (node.type === 'tag' && node.name === 'h4' && node.attribs.class && (node.attribs.class.indexOf("executive__title") > -1)) {
      return <TitleReplace nodeInfo={node} />
    }

    // Replace HTML from Visualizer shortcode with VisualizerIframe component
    // ex: <div id="visualizer-1422-1140468016" class="visualizer-front  visualizer-front-1422"></div>
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.id && node.attribs.class && (node.attribs.class.indexOf("visualizer-front") > -1)) {
      const tableId = (node.attribs.id).split('-')[1]; // ex: turn visualizer-1422-1140468016 into 1422
      const isNumber = /^\d+$/.test(tableId);
      const cssClasses = node.attribs.class;
      const idx = cssClasses.indexOf('type-');
      let tableType = 0;

      // Determine the type of table so can style it accordingly
      if (idx > -1) {
        // tableType will equal a number, such as 1 or 2
        tableType = cssClasses.substring(idx + 5, cssClasses.indexOf(' '));
      }

      if (isNumber) {
        return <VisualizerIframe key={index}
          iframeSrc={`https://thehelm.polb.com/visualizer/?chart=${tableId}&type=${tableType}`} />;
      }
    }

    // Remove embedded <style> from Visualizer
    // ex: <style type="text/css" name="visualizer-custom-css" id="customcss-visualizer-1422">.visualizer-1422oddTableRow {background-color: #dd3333 !important;}</style>
    else if (node.type === 'style' && node.name === 'style' && node.attribs.name && (node.attribs.name.indexOf("visualizer-custom-css") > -1)) {
      return null;
    }

    // Remove edit link from Visualizer. Without doing this, an edit link may show above the table if edit permissions in Visualizer set to "All Users" for a particular table.
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("visualizer-actions") > -1)) {
      return null;
    }

    // Replace Envira Gallery code with Gallery component
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      // const galleryInfo = node.children[0].attribs['data-gallery-images'];
      // const downloadOption = node.children[0];
      // // console.log(JSON.parse(galleryInfo));
      // // console.log(node.children[0].children[0].children[0].children[3].children[0]);

      // return <Gallery key={index} galleryInfo={galleryInfo} previewMode="thumbs" downloadable={downloadOption} />;

      let galleryConfig;

      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].attribs['data-gallery-config']) {
          galleryConfig = node.children[i];
          break;
        }
      }

      return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }

    // Replace Envira Album code with Gallery component
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-album-wrap") > -1)) {
      // console.log("----------------------ALBUM-----------------------")
      // console.log(node);
      const galleryInfo = node.children[0].attribs['data-album-galleries'];

      return <GalleryAlbum key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;
    }
  }

  render() {
    const props = this.props;

    return (
      <article className="parent gutter">
        <h1 className="news__heading">{props.title.replace(/&#8217;/, "'").replace(/&#8216;/, "'")}</h1>
        <React.Fragment>
          {
            props.files ||
              (props.content.indexOf('visualizer-front') > -1) ||
              (props.content.indexOf('envira-gallery-wrap') > -1) ||
              (props.content.indexOf('gform_wrapper') > -1) ||
              (props.content.indexOf('wpdt-c') > -1) ||
              (props.content.indexOf('stats') > -1) ||
              (props.content.indexOf('wpfd-single-file') > -1) ||
              (props.content.indexOf('wp-block-polb-executives') > -1) ||
              (props.content.indexOf('board-meetings') > -1)
              ?
              <div className="content content--subpage">
                {ReactHtmlParser(props.content, { transform: this.replaceWithComponents })}
              </div>
              :
              <div className="content" dangerouslySetInnerHTML={{ __html: props.content }} />
          }
        </React.Fragment>
      </article>
    );
  }
}

export default ParentPageArticle;
