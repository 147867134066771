import React from "react";

import VisualizerIframe from "src/components/visualizer-iframe";
import logo from "src/images/logo-pierpass.png";

/**
 *  PierPASS page content
 */
class Pierpass extends React.Component {
  render() {
    // Is in this form: <p>Night Off Peak: Effective Date 11/12/2016</p>
    const content = this.props.content;
    const textParts = (content.substring((content.indexOf('>') + 1), content.lastIndexOf('<'))).split(':');

    return (
      <section className="pierpass gutter">
        <h2>PierPASS Off Peak Schedule</h2>
        <div className="pierpass__legend-wrapper">
          <img src={logo} width="117" height="81" alt="PierPass logo" className="pierpass__legend-logo" />
          <h4 className="pierpass__legend-heading">Legend:</h4>
          <p className="pierpass__legend-txt">O = Terminal Open on Off Peak, 6pm to 3am Weeknights</p>
          <p className="pierpass__legend-txt">X = Terminal Closed on Off Peak, 8am to 5pm Saturdays</p>
        </div>

        <h4 className="pierpass__date-heading">
          <span className="pierpass__date-note">{textParts[0]}: </span>
          <span className="pierpass__date">{textParts[1]}</span>
        </h4>
        <VisualizerIframe key="5191" iframeSrc="https://thehelm.polb.com/visualizer/?chart=5191&type=3" />
      </section>
    );
  }
}

export default Pierpass;
