
import React from "react";
// import { graphql } from 'gatsby';
import Helmet from "react-helmet";
import orderBy from 'lodash.orderby';
// import Header from "../../components/header";
// import Footer from "../../components/footer";
// import GlobalAlert from "../../components/global-alert";

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Announcement from "src/components/announcement";
import Breadcrumb from "src/components/breadcrumb";
import Events from "src/components/events";
import Hero from "src/components/hero";
import Icons from "src/components/icons";
import SearchSubpage from "src/components/search-subpage";
import SocialMediaPanel from "src/components/social-media-panel";
import SubNav from "src/components/subnav";
import SubpageArticle from "src/components/subpage-article";                    // For all subpages except...
import SubpageArticleDocuments from "src/components/subpage-article-documents"; // Documents pages only

// Only PortOperations page uses this
import PortOperations from "src/components/port-operations";

// In rare cases, we render the parent page's content
import ParentPageArticle from "src/components/parent-page-article";

// Only Community Grants Program uses this
// import CommunityGrantsInfoSubpage from "src/components/community-grants-info-subpage";

// Only Contact Us uses this
import Contact from "src/components/contact";

// Only Jobs page uses this
import Jobs from "src/components/jobs/jobs";
import SocialMedia from "src/components/social-media.js"

// Only PierPASS page uses this
import Pierpass from "src/components/pierpass";

// Only Resource pages use this
import Resource from "src/components/resource/resource";

import loading from "../images/loading.gif";

import 'babel-polyfill'



class PageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.createSubpagesLookup = this.createSubpagesLookup.bind(this);
    this.createSubnavHash = this.createSubnavHash.bind(this);
    this.createSubnavData = this.createSubnavData.bind(this);
    this.handleUpdateState = this.handleUpdateState.bind(this);
    this.getElementDistance = this.getElementDistance.bind(this);
    this.getEventsToShow = this.getEventsToShow.bind(this);
    this.getEventsHeading = this.getEventsHeading.bind(this);
    this.createArticleProps = this.createArticleProps.bind(this);
    this.setDocumentsGalleriesTotal = this.setDocumentsGalleriesTotal.bind(this);
    this.setGrandChildrenData = this.setGrandChildrenData.bind(this);
    this.handleChildContent = this.handleChildContent.bind(this);
    this.replaceChildContent = this.replaceChildContent.bind(this);
    this.subnavInitialSetUp = this.subnavInitialSetUp.bind(this);
    this.determineWhichPageToShow = this.determineWhichPageToShow.bind(this);
  }

  state = {
    pageInfo: [],
    pageID: 0,
    subpageInfo: [],
    subsubpageInfo: [],
    activeSubpage: 0,
    contentWrap: null,
    initialActiveSubpageParent: 0,
    documentsGalleriesTotal: 0,
    listOfPages: [],
    loaded: 0,
    activeSubpageTitle: "",
    childContent: [],
    browserHash: "",
    contentRendered: false,
    samePageDiffHash: false,
    // SUBNAV STATE ITEMS //
    subSubNavOpenStatus: {},
    grandChildrenData: [],
    subSubnavToOpenOnLoadId: null,
    subSubnavItemToOpenOnLoadSlug: null,
    subSubnavParentBtnClicked: false,
    subSubnavOpenedOnLoad: false,
    noAutoScroll: false
  }

  componentDidMount() {
    //let subpageArray = [];
    // let subsubpageArray = [];

    const firstCall = async () => {
      let requestOptions = {
        origin: "https://polb.com"
      };

      const response = await fetch(`https://thehelm.polb.com/wp-json/wp/v2/pages?slug=${this.props.pageContext.slug}`, requestOptions);
      const json = await response.json();

      if (json.length > 0) {
        this.setState({ pageInfo: json, pageID: json[0].id }, () => secondCall());
      } else {
        console.log("First response returned empty");
      }
    }

    const secondCall = async () => {
      let requestOptions = {
        origin: "https://polb.com"
      };

      const response = await fetch(`https://thehelm.polb.com/wp-json/wp/v2/pages?parent=${this.state.pageID}&per_page=100&_fields=id,parent,link,title,tags,slug,menu_order`, requestOptions);
      const json = await response.json();
      if (json.length > 0) {
        //subpageArray = json;
        this.setState({ subpageInfo: json }, () => {
          if (this.state.pageInfo.length > 0 && this.state.subpageInfo.length > 0 && this.state.loaded === 0) {
            this.setState({
              loaded: 1,
              contentWrap: document.getElementById('content-wrap')
            })

            // Check the hash when page loads
            if (typeof window !== 'undefined') {
              const urlHash = window.location.hash.substring(1);
              // this.setInitialSubnavState(urlHash);
              this.subnavInitialSetUp();
            }
          }
        });
      } else {
        console.log("Second response returned empty");
      }
    }

    firstCall();
  }


  componentWillReceiveProps(nextProps) {
    // console.log(window.location);
    // console.log(this.state);
    // console.log(this.props);
    // console.log(nextProps);

    if (this.props.location.pathname === nextProps.location.pathname && nextProps.location.hash !== this.state.browserHash) {
      // console.log("yup");

      if (typeof window !== 'undefined') {
        const location = this.getElementDistance(document.getElementById('content-wrap'));
        window.scrollTo(0, location - 100);
      }

      this.subnavInitialSetUp();
      this.setState({ samePageDiffHash: true });
    }
  }



  subnavInitialSetUp() {
    let subSubNavOpenStatus = {};
    let defaultPage;

    // reconfigure subpage data into subnav format
    const isDocumentsPage = this.state.pageInfo[0] && this.state.pageInfo[0].tagNames.includes("dev_only_id_documents");
    const childrenData = orderBy(this.state.subpageInfo.map(item => item), ['menu_order']);
    const subnavChildrenData = childrenData ? this.createSubnavData(childrenData, isDocumentsPage) : [];

    // get all buttons in subnav that toggle a subnav and get all buttons that toggle a subsubnav
    const btnsOfSubnavs = document.querySelectorAll('#subnav > li.subnav__item > a');
    const btnsOfSubsubnavs = document.querySelectorAll('#subnav [data-wordpress-id]');

    const firstSubnavItem = subnavChildrenData[0];
    let startsWithSubsubnav = false;

    if (typeof window !== 'undefined') {
      const urlHash = window.location.hash.substring(1);
      defaultPage = this.determineWhichPageToShow(urlHash, subnavChildrenData);
    }

    // console.log(firstSubnavItem);
    // console.log(defaultPage);
    // console.log(btnsOfSubnavs);
    // console.log(btnsOfSubsubnavs);


    if (typeof defaultPage === "string" && defaultPage === "first general item") {
      if (btnsOfSubsubnavs.length > 0) {
        for (let index = 0; index < btnsOfSubsubnavs.length; index++) {
          const wordpressId = parseInt(btnsOfSubsubnavs[index].dataset.wordpressId, 10);

          // set state of each subsubnav parent to be false initially
          subSubNavOpenStatus[`btn-${wordpressId}`] = false;

          if (wordpressId === firstSubnavItem.wordpress_id) {
            startsWithSubsubnav = true;
            this.setState({ subSubnavToOpenOnLoadId: wordpressId, noAutoScroll: true, subSubnavParentBtnClicked: true, browserHash: window.location.hash }, () => btnsOfSubsubnavs[index].click());
          }
        }
      }

      if (!startsWithSubsubnav) {
        this.setState({ noAutoScroll: true, browserHash: window.location.hash }, () => btnsOfSubnavs[0].click());
      }
    } else if (typeof defaultPage === "object" && defaultPage[0] === "not a subsubnav") {
      for (let index = 0; index < btnsOfSubnavs.length; index++) {
        if (defaultPage[1] === parseInt(btnsOfSubnavs[index].dataset.wordpressid, 10)) {
          btnsOfSubnavs[index].click();
          break;
        }
      }
    } else if (typeof defaultPage === "object" && defaultPage[0] === "is a subsubnav parent") {
      for (let index = 0; index < btnsOfSubsubnavs.length; index++) {
        const wordpressId = parseInt(btnsOfSubsubnavs[index].dataset.wordpressId, 10);

        // set state of each subsubnav parent to be false initially
        subSubNavOpenStatus[`btn-${wordpressId}`] = false;

        if (defaultPage[1] === wordpressId) {
          this.setState({ subSubnavToOpenOnLoadId: wordpressId, browserHash: window.location.hash }, () => btnsOfSubsubnavs[index].click());
        }
      }
    } else if (typeof defaultPage === "object" && defaultPage[0] === "is a subsubnav item") {
      for (let index = 0; index < btnsOfSubsubnavs.length; index++) {
        const wordpressId = parseInt(btnsOfSubsubnavs[index].dataset.wordpressId, 10);
        const wordpressSlug = btnsOfSubsubnavs[index].dataset.wordpressSlug;

        // set state of each subsubnav parent to be false initially
        subSubNavOpenStatus[`btn-${wordpressId}`] = false;

        if (defaultPage[1] === wordpressSlug) {
          this.setState({
            subSubnavToOpenOnLoadId: wordpressId,
            subSubnavItemToOpenOnLoadSlug: defaultPage[2],
            browserHash: window.location.hash
          }, () => btnsOfSubsubnavs[index].click());
        }
      }
    }

    this.setState({ subSubNavOpenStatus: subSubNavOpenStatus });
  }


  determineWhichPageToShow(urlHash, subnavChildrenData) {
    let subpages = [];
    let whichSubnavItemGetsClicked;

    if (subnavChildrenData.length > 0) {
      subpages = this.createSubpagesLookup(subnavChildrenData);
    }

    const activeSubpage = subpages.find(page => page.hash === urlHash);

    // console.log(subnavChildrenData);
    // console.log(activeSubpage);

    if (!activeSubpage && urlHash === "") {
      // url does not have a hash
      whichSubnavItemGetsClicked = "first general item";
    } else if (activeSubpage && (!activeSubpage.tagNames || activeSubpage.tagNames === 303)) {
      // url has hash and element doesn't have tags, meaning it is not a subsubnav parent
      whichSubnavItemGetsClicked = ["not a subsubnav", activeSubpage.wordpress_id];
    } else if (activeSubpage && activeSubpage.tagNames) {
      // url has hash and element has tags, meaning it is a subsubnav parent
      whichSubnavItemGetsClicked = ["is a subsubnav parent", activeSubpage.wordpress_id];
    } else if (!activeSubpage && urlHash.indexOf('/') > -1) {
      // url has hash but hash is referencing specific subsubnav item, not subsubnav parent
      whichSubnavItemGetsClicked = ["is a subsubnav item", urlHash.substring(0, urlHash.indexOf('/')), urlHash.substring(urlHash.indexOf('/') + 1)];
    } else {
      // url has an invalid hash so we redirect to first item in Subnav
      whichSubnavItemGetsClicked = "first general item";
    }

    return whichSubnavItemGetsClicked;
  }



  /**
   * Create array of each wordpress_id and hash
   * @param subpagesData  Is data for either childrenData or grandChildrenData
   */
  createSubpagesLookup(subpagesData) {
    const subpages = subpagesData.map(child => {
      return {
        wordpress_id: child.wordpress_id,
        wordpress_parent: child.wordpress_parent,
        hash: this.createSubnavHash(child.link),
        title: child.title,
        tagNames: child.tag
      }
    });

    return subpages;
  }


  /**
   * Create a hash value for a subpage to use, based on its full URL (the link field in WP GraphQL)
   * @param url   A URL like "https://thehelm.polb.com/port-operations-and-facilities/containerized-tenants/long-beach-container-terminal/"
   * @return      A portion of the url, like "containerized-tenants/long-beach-container-terminal"
   */
  createSubnavHash(url) {
    let hash = url.substring(url.indexOf("/", url.indexOf("://") + 3), url.length - 1);
    hash = hash.substring(1);
    hash = hash.substring(hash.indexOf("/"));

    if (hash.charAt(0) === '/') {
      hash = hash.substring(1);
    }

    return hash;
  }


  /**
   * A page's subnav only needs a subset of the page's full child and grandchild page data
   */
  createSubnavData(children, isDocumentsPage) {
    let data = [];
    data = children.map(child => {
      let childData = {
        link: child.link,
        menu_order: child.menu_order,
        slug: child.slug,
        title: child.title,
        wordpress_id: child.id,
        wordpress_parent: child.parent,
        tag: child.tags[0]
      };

      // Determine number of files in a Documents subpage so can show the total in subnav
      if (isDocumentsPage && child.files) {
        const initialValue = 0;

        childData.filesTotal = child.files.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.data.length;
        }, initialValue);
      }

      return childData;
    });

    return data;
  }

  /**
   * For Documents > Photos & Videos only: set the total number of galleries passed to <SubNav> so it can display it in parentheses
   */
  setDocumentsGalleriesTotal(totalGalleries) {
    this.setState({ documentsGalleriesTotal: totalGalleries });
  }


  /**
   * Change which subpage shows when user chooses subnav (or sub-subnav) link
   * @param wordpressId   The wordpress_id from API for the selected page
   * @param hash          The hash value from link's href
   */
  handleUpdateState(wordpressId, hash, title) {
    this.setState({
      activeSubpage: wordpressId,
      activeSubpageTitle: title
    },
      function () {
        // Force iframe-resizer to recalculate height of each Visualizer iframe on the currently active subpage because it doesn't do so when iframe is hidden
        if (typeof window !== 'undefined') {
          const selectedIframes = window.document.querySelectorAll('#content-wrap .article--show .visualizer, iframe.stellar-gravity-form');

          if (selectedIframes) {
            // Detect whether browser is Internet Explorer 6-11 or not
            const isIE = /*@cc_on!@*/false || !!document.documentMode;

            if (!isIE) {
              selectedIframes.forEach(iframe => iframe.iFrameResizer.resize());
            }
          }
        }
      }
    );

    const noAutoScroll = this.state.noAutoScroll;

    if (typeof window !== 'undefined') {
      // Show hash in URL
      window.history.replaceState(null, null, hash);

      // Automatically scroll to content section unless no hash is specified in url
      if (!noAutoScroll) {
        // Scroll to 100 pixels above the content area
        // const location = this.getElementDistance(this.state.contentWrap);
        const location = this.getElementDistance(document.getElementById('content-wrap'));
        window.scrollTo(0, location - 100);
      }
    }
  }



  /**
     * Send appropiate child content for the subpage
  */
  handleChildContent(content) {
    if (!this.state.noAutoScroll) {
      const location = this.getElementDistance(document.getElementById('content-wrap'));
      window.scrollTo(0, location - 100);
    }

    this.setState({ childContent: content, browserHash: window.location.hash, contentRendered: true, noAutoScroll: false });
  }


  replaceChildContent() {
    this.setState({ childContent: [], samePageDiffHash: false });
  }



  /**
   * Get an element's distance from the top of the document (not the viewport)
   */
  getElementDistance(element) {
    let location = 0;

    if (element.offsetParent) {
      do {
        location += element.offsetTop;
        element = element.offsetParent;
      } while (element);
    }

    return location >= 0 ? location : 0;
  }


  /**
   * Create props for article component, whether it's a standard article or downloads article
   */
  createArticleProps(child, childrenData) {
    const articleProps = {
      key: child.slug,
      content: `<h2>${child.title.rendered}</h2>${child.content.rendered}`,
      slug: child.slug,
      files: child.files,
      active: parseInt(this.state.activeSubpage, 10) === parseInt(child.id, 10),
      tagNames: child.tagNames,
      wordpressid: child.id,
      pageInfo: childrenData
    }

    return articleProps;
  }


  /**
   * Get calendar event(s) to show below the subnav in the sidebar
   */
  getEventsToShow(tags) {
    let eventsTags;
    let eventsIds = [];

    // In WordPress, the parent page can have a tag for each event it should list below the subnav.
    // The tags are in the form of event_id_NUM, where NUM is the WP id of the event post. For example, event_id_58.
    if (tags) {
      // get all the event tags
      eventsTags = tags.filter(event => event.indexOf('event_id_') > -1);

      if (eventsTags) {
        // get only the id portion of the event tags
        eventsIds = eventsTags.map(eventTag => eventTag.substring(eventTag.lastIndexOf('_') + 1));
      }
    }

    return eventsIds;
  }

  /**
   * Get heading to display above the calendar event(s) that are below the subnav in the sidebar
   */
  getEventsHeading(tags) {
    const eventsHeadingTags = tags.filter(event => event.indexOf('events_heading_') > -1);
    let eventsHeading;

    if (eventsHeadingTags[0]) {
      eventsHeading = eventsHeadingTags[0].substring(eventsHeadingTags[0].indexOf('events_heading_') + 15);
      eventsHeading = eventsHeading.replace(/_/g, ' ');
    }

    return eventsHeading;
  }

  setGrandChildrenData(grandChildren) {
    this.setState({ subsubpageInfo: grandChildren });
  }


  render() {
    const pageData = this.state.pageInfo[0];
    const childrenData = orderBy(this.state.subpageInfo.map(item => item), ['menu_order']); // child pages
    const grandChildrenData = this.state.subsubpageInfo.length > 0 && this.state.subsubpageInfo.map(item => item); // grandchild pages
    const hasHero = (pageData && pageData.parent === 0 && pageData.acf && pageData.acf.hero_background) ? true : false;
    // const pageCategories = [{ name: "Port Info", slug: "port-info" }];
    const childContentItem = this.state.childContent.length > 0 && this.state.childContent;

    const isDocumentsPage = pageData && pageData.tagNames.includes("dev_only_id_documents");

    const subnavChildrenData = childrenData ? this.createSubnavData(childrenData, isDocumentsPage) : [];
    // const subnavGrandChildrenData = grandChildrenData ? this.createSubnavData(grandChildrenData) : [];

    const isPierPass = false;
    const hasSubpages = (childrenData && childrenData.length > 0) ? true : false;
    const renderSubpages = !isPierPass ? true : false;

    // Get ids of calendar events to show in the sidebar
    const sidebarEventsIds = pageData ? this.getEventsToShow(pageData.tagNames) : [];

    // Get heading to display above the events
    let eventsHeading;
    if (sidebarEventsIds.length > 0) {
      eventsHeading = pageData && this.getEventsHeading(pageData.tagNames);
    }

    let offsetMainPage = false;
    let offsetMainPageCategory;
    if (pageData) {
      if (pageData.tagNames.includes('dev_only_id_community_grants_program')) {
        offsetMainPage = true;
        offsetMainPageCategory = "Community Grants Program";
      } else if (pageData.tagNames.includes('dev_only_id_education_resources')) {
        offsetMainPage = true;
        offsetMainPageCategory = "Education";
      }
    }

    // console.log(this.state);

    return (
      <React.Fragment>
        {
          pageData ?
            <Layout hasHero={hasHero} pageTitle={pageData.title.rendered}>
              <Icons />
              <SEO title={pageData.title.rendered} description={pageData.excerpt.rendered} />

              {!hasHero && (
                <Helmet>
                  <body className="no-hero" />
                </Helmet>
              )}

              {hasHero && (
                <Hero type="short" button={pageData.acf.button} buttonLabel={pageData.acf.button_label}
                  headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2}
                  heroBackgroundUrl={pageData.acf.hero_background} subheading={pageData.acf.subheading}
                  mobileHeroBackgroundUrl={pageData.acf.mobile_background} />
              )}

              { /* Display breadcrumb in relevant pages. */
                offsetMainPage ?
                  <Breadcrumb categories={offsetMainPageCategory} pageTitle={this.state.activeSubpageTitle}
                    socialShare={true} />
                  :
                  !pageData.tagNames.includes('dev_only_id_contact')
                  && !pageData.tagNames.includes('dev_only_resource_page')
                  && !pageData.tagNames.includes('dev_only_id_jobs_page')
                  && !pageData.tagNames.includes("dev_only_id_documents")
                  && <Breadcrumb categories={pageData.categories} pageTitle={pageData.title.rendered} />
              }

              <SocialMediaPanel data={pageData} />

              { /* Add component only to Contact Us. */}
              {pageData.tagNames.includes('dev_only_id_contact')
                && <Contact />
              }

              { /* Add component only to Jobs page. */}
              {pageData.tagNames.includes('dev_only_id_jobs_page')
                && <Jobs content={pageData.content.rendered} />
              }

              { /* Add component only to PierPASS page. */}
              {isPierPass
                && <Pierpass content={pageData.content.rendered} />
              }

              { /* Add component only to Port Operations page. */}
              {pageData.tagNames.includes('dev_only_id_port_operations')
                && <PortOperations content={pageData.content.rendered} />
              }

              { /* Add component only to a Resource page (there are several). */}
              {pageData.tagNames.includes('dev_only_resource_page')
                && <Resource tagNames={pageData.tagNames} />
              }

              { /* In rare cases, the content for a parent page (like Port Info > Privacy) is managed in the parent page itself in WordPress. */}
              {
                pageData.tagNames.includes('dev_only_render_parent_page_content')
                && <ParentPageArticle title={pageData.title.rendered} content={pageData.content.rendered} />
              }

              { /* Display subpages in standard two-column layout. The vast majority of pages use this. */}
              {hasSubpages && renderSubpages ?
                <div
                  className={`grid grid--subpage gutter ${pageData.tagNames.includes("dev_only_id_documents") ? 'documents--subpage' : ''}`}>
                  <aside className="sidebar" aria-label="sidebar">
                    {/*<h2 className="sidebar__heading">Explore The Port</h2>*/}
                    <SearchSubpage />

                    <SubNav
                      subpages={subnavChildrenData}
                      subSubnavs={this.setGrandChildrenData}
                      active={this.state.activeSubpage}
                      initialActiveSubpageParent={this.state.initialActiveSubpageParent}
                      updateState={this.handleUpdateState}
                      createSubnavHash={this.createSubnavHash}
                      documentsGalleriesTotal={this.state.documentsGalleriesTotal}
                      isDocumentsPage={isDocumentsPage}
                      childContent={this.handleChildContent}
                      replaceContent={this.replaceChildContent}
                      currentHash={this.state.browserHash}
                      contentRendered={this.state.contentRendered}
                      subSubnavToOpenOnLoadId={this.state.subSubnavToOpenOnLoadId}
                      subSubnavItemToOpenOnLoadSlug={this.state.subSubnavItemToOpenOnLoadSlug}
                      samePageDiffHash={this.state.samePageDiffHash}
                    />

                    {sidebarEventsIds.length > 0 &&
                      <React.Fragment>
                        {eventsHeading &&
                          <Announcement
                            title={eventsHeading}
                          />
                        }
                        <Events eventsType="sidebar" eventsIds={sidebarEventsIds} />
                      </React.Fragment>
                    }

                    {(pageData.parent === 0 && pageData.acf && pageData.acf.announcement_content) && (
                      <Announcement
                        title={pageData.acf.announcement_title}
                        content={pageData.acf.announcement_content}
                      />
                    )}
                  </aside>

                  <div id="content-wrap" className="content-wrap">
                    {
                      this.state.childContent.length > 0 ?
                        <React.Fragment>
                          {
                            childContentItem
                            && childContentItem.map((child, index) =>
                              (
                                !isDocumentsPage
                                  ? <SubpageArticle {...(this.createArticleProps(child, childContentItem))} />
                                  : <SubpageArticleDocuments apiUrl="https://thehelm.polb.com"
                                    setDocumentsGalleriesTotal={this.setDocumentsGalleriesTotal} {...(this.createArticleProps(child, childContentItem))} />
                              )
                            )
                          }

                          {
                            grandChildrenData.length > 0 &&
                            grandChildrenData.map((child, index) => {
                              let childTitle;
                              if (child.title.rendered) {
                                childTitle = child.title.rendered;
                              } else {
                                childTitle = child.title;
                              }

                              return (
                                <SubpageArticle
                                  key={child.slug}
                                  content={`<h2>${childTitle}</h2>${child.content.rendered}`}
                                  slug={this.createSubnavHash(child.link)}
                                  files={child.files}
                                  active={parseInt(this.state.activeSubpage, 10) === parseInt(child.id, 10)}
                                  wordpressid={child.id}
                                  pageInfo={pageData}
                                />
                              );
                            })
                          }
                        </React.Fragment>
                        :
                        <div className="loading-container"><img src={loading} alt="loading..." /></div>
                    }
                  </div>
                </div>
                :
                null
              }

              { /* Add Juicer feed to Jobs page only. */}
              {pageData.tagNames.includes('dev_only_id_jobs_page')
                && <SocialMedia title="Port Business and Career News" feed="port_of_long_beach" />
              }
            </Layout>
            :
            null
        }
      </React.Fragment>
    )
  }
}


export default PageTemplate;
























































// import React from "react";
// import { graphql } from 'gatsby';
// import Helmet from "react-helmet";
// import orderBy from 'lodash.orderby';

// import Layout from "src/components/layout";
// import SEO from "src/components/seo";
// import Announcement from "src/components/announcement";
// import Breadcrumb from "src/components/breadcrumb";
// import Events from "src/components/events";
// import Hero from "src/components/hero";
// import Icons from "src/components/icons";
// import SearchSubpage from "src/components/search-subpage";
// import SocialMediaPanel from "src/components/social-media-panel";
// import SubNav from "src/components/subnav";
// import SubpageArticle from "src/components/subpage-article";                    // For all subpages except...
// import SubpageArticleDocuments from "src/components/subpage-article-documents"; // Documents pages only

// // In rare cases, we render the parent page's content
// import ParentPageArticle from "src/components/parent-page-article";

// // Only Community Grants Program uses this
// // import CommunityGrantsInfoSubpage from "src/components/community-grants-info-subpage";

// // Only Contact Us uses this
// import Contact from "src/components/contact";

// // Only Jobs page uses this
// import Jobs from "src/components/jobs/jobs";

// // Only PierPASS page uses this
// import Pierpass from "src/components/pierpass";

// // Only PortOperations page uses this
// import PortOperations from "src/components/port-operations";

// // Only Resource pages use this
// import Resource from "src/components/resource/resource";

// import 'babel-polyfill'

// /**
//  * Page template used by WordPress pages
//  */
// class PageTemplate extends React.Component {
//   constructor(props) {
//     super(props);
//     this.createSubpagesLookup = this.createSubpagesLookup.bind(this);
//     this.createSubnavData = this.createSubnavData.bind(this);
//     this.createSubnavHash = this.createSubnavHash.bind(this);
//     this.handleUpdateState = this.handleUpdateState.bind(this);
//     this.getElementDistance = this.getElementDistance.bind(this);
//     this.setInitialSubnavState = this.setInitialSubnavState.bind(this);
//     this.createArticleProps = this.createArticleProps.bind(this);
//     this.getEventsToShow = this.getEventsToShow.bind(this);
//     this.getEventsHeading = this.getEventsHeading.bind(this);

//     this.setDocumentsGalleriesTotal = this.setDocumentsGalleriesTotal.bind(this);
//   }

//   /**
//    * activeSubpage  wordpress_id of the active subpage in the subnav
//    * initialActiveSubpageParent  wordpress_parent of active subpage when page initially loads
//    * listOfPages  info about all subpages on this page
//    * documentsGalleriesTotal  only Documents > Photos & Videos uses this
//    */
//   state = {
//     activeSubpage: 0,
//     initialActiveSubpageParent: 0,
//     contentWrap: null,
//     listOfPages: [],
//     documentsGalleriesTotal: 0,
//     activeSubpageTitle: ""
//   };

//   componentDidMount() {
//     this.setState({ contentWrap: document.getElementById('content-wrap') });

//     // Check the hash when page loads
//     if (typeof window !== 'undefined') {
//       const urlHash = window.location.hash.substring(1);
//       this.setInitialSubnavState(urlHash);
//     }
//   }

//   /**
//    * Create array of each wordpress_id and hash
//    * @param subpagesData  Is data for either childrenData or grandChildrenData
//    */
//   createSubpagesLookup(subpagesData) {
//     const subpages = subpagesData.map(child => {
//       return {
//         wordpress_id: child.wordpress_id,
//         wordpress_parent: child.wordpress_parent,
//         hash: this.createSubnavHash(child.link),
//         title: child.title,
//         tagNames: child.tagNames
//       }
//     });

//     return subpages;
//   }

//   /**
//    * Set the initial state of the subnav
//    * @param urlHash   The subpage hash in the URL when page loads
//    */
//   setInitialSubnavState(urlHash) {
//     // TODO: look into removing redundancy of the childrenData line and same line in render(). look into same for grandChildren.
//     const grandChildren = this.props.data.queryGrandChildren.edges;
//     const childrenData = orderBy(this.props.data.queryChildren.edges.map(item => item.node), ['menu_order']);
//     const grandChildrenData = (grandChildren.length > 0) ? orderBy(grandChildren.map(item => item.node), ['menu_order']) : '';

//     // Create array of each wordpress_id, hash and title
//     let subpages = [];

//     if (childrenData.length > 0 && grandChildrenData.length > 0) {
//       subpages = this.createSubpagesLookup(childrenData).concat(this.createSubpagesLookup(grandChildrenData));
//     } else if (childrenData.length > 0) {
//       subpages = this.createSubpagesLookup(childrenData);
//     }

//     // Now determine which page to make active by default
//     let defaultActiveSubpage = 0;
//     // Its parent
//     let initialActiveSubpageParent = 0;
//     // Its title
//     let defaultSubpageTitle = "";

//     // First, need to check for length because some pages don't have children -- meaning no subpages. An example is if WP has a parent page but not at least one child page assigned to it (either because the content is yet to be added or because the page will never have children). NOTE: the check for 'dev_only_render_in_parent_not_as_a_subpage' is an inelegant way of preventing the PierPASS URL (/business/pierpass) from changing to /business/pierpass#pierpass-table. The pierpass-table subpage only exists in WP to make type=3 be applied to the Visualizer table; that subpage is not supposed to be rendered on its own, so adding that dev_only tag to the pierpass-table page in combination with the check for it below prevents the URL from changing.
//     if (subpages.length > 0 && !subpages[0].tagNames.includes('dev_only_render_in_parent_not_as_a_subpage')) {
//       // data of the page (if any) that is in the URL hash when page loads 
//       const activeSubpage = subpages.find(page => page.hash === urlHash);

//       if (!activeSubpage && childrenData[0].content) {
//         // no hash in the URL and first subnav link has content, so it does not have a sub-subnav
//         defaultActiveSubpage = `${subpages[0].wordpress_id}`;
//         window.history.replaceState(null, null, `#${subpages[0].hash}`);
//       } else if (!activeSubpage) {
//         // the first subnav item is a parent with a sub-subnav
//         const firstSubnavHash = subpages[0].hash;
//         const firstSubSubnav = subpages.find(page => page.hash.indexOf(`${firstSubnavHash}/`) > -1);

//         if (firstSubSubnav) {
//           defaultActiveSubpage = firstSubSubnav.wordpress_id;
//           initialActiveSubpageParent = firstSubSubnav.wordpress_parent;
//           defaultSubpageTitle = firstSubSubnav.title;
//           window.history.replaceState(null, null, `#${firstSubSubnav.hash}`);
//         }
//       } else {
//         // hash appears in URL so we'll show that page by default
//         defaultActiveSubpage = activeSubpage.wordpress_id;
//         initialActiveSubpageParent = activeSubpage.wordpress_parent;
//         defaultSubpageTitle = activeSubpage.title;
//       }

//       // set the default page, its parent, and the list of pages
//       this.setState({
//         activeSubpage: defaultActiveSubpage,
//         initialActiveSubpageParent: initialActiveSubpageParent,
//         listOfPages: subpages,
//         activeSubpageTitle: defaultSubpageTitle
//       });
//     }
//   }

//   /**
//    * Create a hash value for a subpage to use, based on its full URL (the link field in WP GraphQL)
//    * @param url   A URL like "https://thehelm.polb.com/port-operations-and-facilities/containerized-tenants/long-beach-container-terminal/"
//    * @return      A portion of the url, like "containerized-tenants/long-beach-container-terminal"
//    */
//   createSubnavHash(url) {
//     let hash = url.substring(url.indexOf("/", url.indexOf("://") + 3), url.length - 1);
//     hash = hash.substring(1);
//     hash = hash.substring(hash.indexOf("/"));

//     if (hash.charAt(0) === '/') {
//       hash = hash.substring(1);
//     }

//     return hash;
//   }

//   /**
//    * Change which subpage shows when user chooses subnav (or sub-subnav) link
//    * @param wordpressId   The wordpress_id from API for the selected page
//    * @param hash          The hash value from link's href
//    */
//   handleUpdateState(wordpressId, hash, title) {
//     this.setState({
//       activeSubpage: wordpressId,
//       activeSubpageTitle: title
//     },
//       function () {
//         // Force iframe-resizer to recalculate height of each Visualizer iframe on the currently active subpage because it doesn't do so when iframe is hidden
//         if (typeof window !== 'undefined') {
//           const selectedIframes = window.document.querySelectorAll('#content-wrap .article--show .visualizer, iframe.stellar-gravity-form');

//           if (selectedIframes) {
//             // Detect whether browser is Internet Explorer 6-11 or not
//             const isIE = /*@cc_on!@*/false || !!document.documentMode;

//             if (!isIE) {
//               selectedIframes.forEach(iframe => iframe.iFrameResizer.resize());
//             }
//           }
//         }
//       }
//     );

//     if (typeof window !== 'undefined') {
//       // Show hash in URL
//       window.history.replaceState(null, null, hash);

//       // Scroll to 100 pixels above the content area
//       const location = this.getElementDistance(this.state.contentWrap);
//       window.scrollTo(0, location - 100);
//     }
//   }

//   /**
//    * Get an element's distance from the top of the document (not the viewport)
//    */
//   getElementDistance(element) {
//     let location = 0;

//     if (element.offsetParent) {
//       do {
//         location += element.offsetTop;
//         element = element.offsetParent;
//       } while (element);
//     }

//     return location >= 0 ? location : 0;
//   }

//   /**
//    * Create props for article component, whether it's a standard article or downloads article
//    */
//   createArticleProps(child, childrenData) {
//     const articleProps = {
//       key: child.slug,
//       content: `<h2>${child.title}</h2>${child.content}`,
//       slug: child.slug,
//       files: child.files,
//       active: parseInt(this.state.activeSubpage, 10) === parseInt(child.wordpress_id, 10),
//       tagNames: child.tagNames,
//       wordpressid: child.wordpress_id,
//       pageInfo: childrenData
//     }

//     return articleProps;
//   }

//   /**
//    * Get calendar event(s) to show below the subnav in the sidebar
//    */
//   getEventsToShow(tags) {
//     let eventsTags;
//     let eventsIds = [];

//     // In WordPress, the parent page can have a tag for each event it should list below the subnav.
//     // The tags are in the form of event_id_NUM, where NUM is the WP id of the event post. For example, event_id_58.
//     if (tags) {
//       // get all the event tags
//       eventsTags = tags.filter(event => event.indexOf('event_id_') > -1);

//       if (eventsTags) {
//         // get only the id portion of the event tags
//         eventsIds = eventsTags.map(eventTag => eventTag.substring(eventTag.lastIndexOf('_') + 1));
//       }
//     }

//     return eventsIds;
//   }

//   /**
//    * Get heading to display above the calendar event(s) that are below the subnav in the sidebar
//    */
//   getEventsHeading(tags) {
//     const eventsHeadingTags = tags.filter(event => event.indexOf('events_heading_') > -1);
//     let eventsHeading;

//     if (eventsHeadingTags[0]) {
//       eventsHeading = eventsHeadingTags[0].substring(eventsHeadingTags[0].indexOf('events_heading_') + 15);
//       eventsHeading = eventsHeading.replace(/_/g, ' ');
//     }

//     return eventsHeading;
//   }


//   // children values used by <SubNav> when passed as subpages={childrenData}.
//   // in createSubnavItem and createSubnavLi (both as child):
//   // * child.wordpress_id
//   // * child.slug
//   // * child.link
//   // * child.title

//   // const subnavChildrenData = {
//   //   link: childrenData.link,
//   //   slug: childrenData.slug,
//   //   title: childrenData.title,
//   //   wordpress_id: childrenData.wordpress_id
//   // }

//   /**
//    * A page's subnav only needs a subset of the page's full child and grandchild page data
//    */
//   createSubnavData(children, isDocumentsPage) {
//     let data = [];

//     data = children.map(child => {
//       let childData = {
//         link: child.link,
//         menu_order: child.menu_order,
//         slug: child.slug,
//         title: child.title,
//         wordpress_id: child.wordpress_id,
//         wordpress_parent: child.wordpress_parent
//       };

//       // Determine number of files in a Documents subpage so can show the total in subnav
//       if (isDocumentsPage && child.files) {
//         const initialValue = 0;

//         childData.filesTotal = child.files.reduce(function (accumulator, currentValue) {
//           return accumulator + currentValue.data.length;
//         }, initialValue);
//       }

//       return childData;
//     });

//     return data;
//   }

//   /**
//    * For Documents > Photos & Videos only: set the total number of galleries passed to <SubNav> so it can display it in parentheses
//    */
//   setDocumentsGalleriesTotal(totalGalleries) {
//     this.setState({ documentsGalleriesTotal: totalGalleries });
//   }

//   render() {
//     const siteMetadata = this.props.data.site.siteMetadata;
//     const pageData = this.props.data.queryThisPage; // parent page
//     const childrenData = orderBy(this.props.data.queryChildren.edges.map(item => item.node), ['menu_order']); // child pages
//     const grandChildrenData = this.props.data.queryGrandChildren.edges.map(item => item.node); // grandchild pages

//     const isDocumentsPage = pageData.tagNames.includes("dev_only_id_documents");

//     // These will be passed to <SubNav> because it only needs a subset of the data of subpages and subpages
//     const subnavChildrenData = this.createSubnavData(childrenData, isDocumentsPage);
//     const subnavGrandChildrenData = this.createSubnavData(grandChildrenData);

//     // Check if page has at least one subpage. hasSubpages is true for the vast majority of parent pages. But there are special pages like Port Info > Privacy that don't have subpages. They have a single column instead of subpage content in two columns.
//     const hasSubpages = (childrenData && childrenData.length > 0) ? true : false;
//     // const isPierPass = pageData.tagNames.includes('dev_only_id_pierpass');
//     const isPierPass = false;
//     const renderSubpages = !isPierPass ? true : false;

//     // Check if page has a hero. Most do, but Port Info > Privacy is an example of one that doesn't.
//     const hasHero = (pageData.wordpress_parent === 0 && pageData.acf && pageData.acf.hero_background) ? true : false;

//     // Get ids of calendar events to show in the sidebar
//     const sidebarEventsIds = this.getEventsToShow(pageData.tagNames);

//     // Get heading to display above the events
//     let eventsHeading;
//     if (sidebarEventsIds.length > 0) {
//       eventsHeading = this.getEventsHeading(pageData.tagNames);
//     }

//     let offsetMainPage = false;
//     let offsetMainPageCategory;
//     if (pageData.tagNames.includes('dev_only_id_community_grants_program')) {
//       offsetMainPage = true;
//       offsetMainPageCategory = "Community Grants Program";
//     } else if (pageData.tagNames.includes('dev_only_id_education_resources')) {
//       offsetMainPage = true;
//       offsetMainPageCategory = "Education";
//     }


//     // console.log("----------------pageData-----------------");
//     // console.log(pageData);
//     // console.log("----------------subPageData-----------------");
//     // console.log(childrenData);

//     // console.log(grandChildrenData);
//     // console.log(this.state);


//     return (
//       <Layout hasHero={hasHero} pageTitle={pageData.title}>
//         <Icons />
//         <SEO title={pageData.title} description="" />

//         {!hasHero && (
//           <Helmet>
//             <body className="no-hero" />
//           </Helmet>
//         )}

//         {hasHero && (
//           <Hero type="short" button={pageData.acf.button} buttonLabel={pageData.acf.button_label}
//             headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2}
//             heroBackgroundUrl={pageData.acf.hero_background} subheading={pageData.acf.subheading}
//             mobileHeroBackgroundUrl={pageData.acf.mobile_background} />
//         )}

//         { /* Display breadcrumb in relevant pages. */
//           offsetMainPage ?
//             <Breadcrumb categories={offsetMainPageCategory} pageTitle={this.state.activeSubpageTitle}
//               socialShare={true} />
//             :
//             !pageData.tagNames.includes('dev_only_id_contact')
//             && !pageData.tagNames.includes('dev_only_resource_page')
//             && !pageData.tagNames.includes('dev_only_id_jobs_page')
//             && !pageData.tagNames.includes("dev_only_id_documents")
//             && <Breadcrumb categories={pageData.categories} pageTitle={pageData.title} />
//         }

//         <SocialMediaPanel />

//         { /* Add component only to Contact Us. */}
//         {pageData.tagNames.includes('dev_only_id_contact')
//           && <Contact />
//         }

//         { /* Add component only to Jobs page. */}
//         {pageData.tagNames.includes('dev_only_id_jobs_page')
//           && <Jobs content={pageData.content} />
//         }

//         { /* Add component only to PierPASS page. */}
//         {isPierPass
//           && <Pierpass content={pageData.content} />
//         }

//         { /* Add component only to Port Operations page. */}
//         {pageData.tagNames.includes('dev_only_id_port_operations')
//           && <PortOperations content={pageData.content} />
//         }

//         { /* Add component only to a Resource page (there are several). */}
//         {pageData.tagNames.includes('dev_only_resource_page')
//           && <Resource tagNames={pageData.tagNames} />
//         }

//         { /* In rare cases, the content for a parent page (like Port Info > Privacy) is managed in the parent page itself in WordPress. */}
//         {
//           pageData.tagNames.includes('dev_only_render_parent_page_content')
//           && <ParentPageArticle title={pageData.title} content={pageData.content} />
//         }

//         { /* Display subpages in standard two-column layout. The vast majority of pages use this. */}
//         {hasSubpages && renderSubpages &&
//           <div
//             className={`grid grid--subpage gutter ${pageData.tagNames.includes("dev_only_id_documents") ? 'documents--subpage' : ''}`}>
//             <aside className="sidebar" aria-label="sidebar">
//               {/*<h2 className="sidebar__heading">Explore The Port</h2>*/}
//               <SearchSubpage />

//               <SubNav
//                 subpages={subnavChildrenData}
//                 subSubnavs={subnavGrandChildrenData}
//                 active={this.state.activeSubpage}
//                 initialActiveSubpageParent={this.state.initialActiveSubpageParent}
//                 updateState={this.handleUpdateState}
//                 createSubnavHash={this.createSubnavHash}
//                 documentsGalleriesTotal={this.state.documentsGalleriesTotal}
//                 isDocumentsPage={isDocumentsPage}
//               />

//               {sidebarEventsIds.length > 0 &&
//                 <React.Fragment>
//                   {eventsHeading &&
//                     <Announcement
//                       title={eventsHeading}
//                     />
//                   }
//                   <Events eventsType="sidebar" eventsIds={sidebarEventsIds} />
//                 </React.Fragment>
//               }

//               {(pageData.wordpress_parent === 0 && pageData.acf && pageData.acf.announcement_content) && (
//                 <Announcement
//                   title={pageData.acf.announcement_title}
//                   content={pageData.acf.announcement_content}
//                 />
//               )}
//             </aside>

//             <div id="content-wrap" className="content-wrap">
//               {
//                 childrenData.length > 0
//                   ? childrenData.map((child, index) =>
//                     (
//                       !isDocumentsPage
//                         ? <SubpageArticle {...(this.createArticleProps(child, childrenData))} />
//                         : <SubpageArticleDocuments apiUrl={siteMetadata.apiUrl}
//                           setDocumentsGalleriesTotal={this.setDocumentsGalleriesTotal} {...(this.createArticleProps(child, childrenData))} />
//                     )
//                   )
//                   : <SubpageArticle content={pageData.content} files={pageData.files} active />
//               }

//               {
//                 grandChildrenData.length > 0 &&
//                 grandChildrenData.map((child, index) =>
//                   <SubpageArticle
//                     key={child.slug}
//                     content={`<h2>${child.title}</h2>${child.content}`}
//                     slug={this.createSubnavHash(child.link)}
//                     files={child.files}
//                     active={parseInt(this.state.activeSubpage, 10) === parseInt(child.wordpress_id, 10)}
//                     wordpressid={child.wordpress_id}
//                     pageInfo={pageData}
//                   />)
//               }
//             </div>
//           </div>
//         }
//       </Layout>
//     )
//   }
// }

// export default PageTemplate;

// export const pageQuery = graphql`
//   query currentPageQuery($wordpress_id: Int, $id: String!) {

//     queryThisPage:wordpressPage(id: { eq: $id }) {
//       id
//       slug
//       title
//       acf {
//         heading_line1
//         heading_line2
//         subheading
//         announcement_title
//         announcement_content
//         button
//         button_label
//         hero_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//         mobile_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//       categories {
//         name
//         slug
//       }

//       content

//       tagNames

//       wordpress_id
//       wordpress_parent
//       menu_order

//       files {
//         filesId
//         data {
//           title
//           size
//           linkdownload
//           ext
//         }
//       }
//     }
//     site {
//       id
//       siteMetadata {
//         title
//         apiUrl
//       }
//     }


//     queryChildren:allWordpressPage(
//       filter: { wordpress_parent: { eq: $wordpress_id } }
//     ){
//       edges {
//         node {
//           id
//           wordpress_id
//           wordpress_parent
//           ancestor
//           menu_order
//           title
//           content
//           link
//           slug
//           tagNames

//           files {
//             filesId
//             data {
//               title
//               size
//               linkdownload
//               ext
//             }
//           }
//         }
//       }
//     }


//   queryGrandChildren:allWordpressPage(
//     filter: { 
//       ancestor: { eq: $wordpress_id }
//       wordpress_parent: { ne: $wordpress_id }
//     }
//   ){
//     edges {
//       node {
//         id
//         wordpress_id
//         wordpress_parent
//         ancestor
//         menu_order
//         title
//         content
//         link
//         slug
//         tagNames

//         files {
//           filesId
//           data {
//             title
//             size
//             linkdownload
//             ext
//           }
//         }
//       }
//     }
//   }

// }
// `;
