import React from "react";

import MapStatic from "src/components/map-static";

/**
 *  Port Operations page content above its subpages
 */
class PortOperations extends React.Component {
  render() {
    const props = this.props;
    // console.log(props);

    return (
      <React.Fragment>
        <section className="port-operations">
          <div className="port-operations__content content content--subpage gutter" dangerouslySetInnerHTML={{ __html: props.content }} />
        </section>
        <MapStatic headingContentType="0" introContentType="0" classModifier="port-operations" />
      </React.Fragment>
    );
  }
}

export default PortOperations;
